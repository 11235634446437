<template>
	<div class="bg-white h-full overflow-y-scroll">
		<div class="h-80 w-full bg-gray-100">
		</div>
		<div class="px-8 py-3 sm:py-10 space-y-6">
			<div class="flex justify-between">
				<h2 class="text-2xl flex items-center font-bold tracking-tight text-gray-900 ">
					{{ get(resource, 'name') }}
				</h2>
				<Badge :is-rounded="true" text-size="sm" px="3" py="2" bg-color="gray-400" text-color="white"
							 :label="get(resource, 'type')" icon-size="6" :icon-name="'LinkIcon'"
							 :is-left="true"/>
			</div>
			<div class="h-80 w-full bg-gray-100 relative flex justify-center items-center">
				<Icon name="PlayIcon" size="20" color="gray-600" custom-class="absolute"/>
			</div>
			<Text size="md" color="black" v-html="get(resource, 'body') || `This is sample text for resource body`"/>
			<Text v-if="get(nextResource, 'id')" size="md" weight="semibold" color="indigo-700" :content="`Next: ${get(nextResource, 'name')}`"
						custom-class="cursor-pointer underline"
						@click="onNext"/>
		</div>
	</div>

</template>

<script>
import Badge from '../components/molecules/Badge/Basic/Basic'
import Icon from '../components/atoms/Icons/Icons'
import Text from '../components/atoms/Text/Text'
import {get} from 'lodash'
import moment from "moment";

export default {
	components: {
		Badge,
		Icon,
		Text,
	},
	data() {
		return {
			resource: null,
			get,
			nextResource: null,
			venture: null,
			company: null,
			resources: [],
		}
	},
	watch: {
		'$route.params': async function (val) {
			console.log(val);
			await this.onInitialize();
		}
	},
	methods: {
		onNext() {
			if (this.$route.params?.ventureId) {
				this.$router.push({name: 'Venture Resource Detail', params: {ventureId: this.$route.params?.ventureId, id: this.nextResource?.id}})
			} else if (this.$route.params?.companyId) {
				this.$router.push({name: 'Ecosystem Resource Detail', params: {companyId: this.$route.params?.companyId, id: this.nextResource?.id}})
			} else {
				this.$router.push({name: 'Resource Detail', params: {id: this.nextResource?.id}})
			}
		},
		async onInitialize() {
			if (this.$route.params?.companyId) {
				// ECOSYSTEM Resource Detail
				const items = await this.actions.company?.getCompanies({where: {id: this.$route.params?.companyId}, getValues: true});
				this.company = items[0];
				this.company?.ventures?.map(v => {
					v.resources?.map(u => {
						this.resources.push(u);
					})
				});
				const index = this.resources?.findIndex(r => r.id === this.$route.params?.id);
				this.resource = this.resources[index];
				this.nextResource = this.resources[index + 1];
			} else if (this.$route.params?.ventureId) {
				// VENTURE Resource Detail
				const items = await this.actions.venture?.getVentures({where: {id: this.$route.params?.ventureId}, getValues: true});
				this.venture = items[0];
				this.resources = this.venture?.resources;
				const index = this.resources?.findIndex(r => r.id === this.$route.params?.id);
				this.resource = this.resources[index];
				this.nextResource = this.resources[index + 1];
			} else {
				// NORMAL Resource Detail
				this.resource = this.state.resource.resources[this.$route.params?.id];
				if (!this.resource?.id) {
					await this.actions.resource.getResources({first: 10000});
					this.resource = this.state.resource.resources[this.$route.params?.id]
				}
				const index = Object.values(this.state.resource.resources)?.findIndex(r => r.id === this.resource.id);
				this.nextResource = Object.values(this.state.resource.resources)[index + 1];
			}
		}
	},
	async created() {
		await this.onInitialize()
	}
}
</script>

<style scoped>

</style>
